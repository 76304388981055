import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Link from '../core/Link';
import { LocaleProvider } from '../core/Context/LocaleProvider';
import HeadingElement from '../core/HeadingElement';
import {
  InternalStyledLink,
  ExternalStyledLink,
  LinkWrapper,
} from '../core/Link/styles';

import {
  SectionProducts,
  ProductListWrapper,
  ProductItem,
  ProductThumbnail,
  ProductBlockNavigation,
  ProductBlockNavLinks,
  ProductItemMetaWrapper,
} from './styles';

const ProductsBlock = ({
  blockTitle,
  products,
  brandSupportLinks,
  headingLevel,
  translations,
  brandSlug,
}) => {
  const locale = useContext(LocaleProvider);
  if (!products || products.length === 0) {
    return null;
  }

  const detailsTranslation =
    translations.filter((item) => item.contentfulid === 'details')[0]?.copy ||
    'Details';

  // const filteredProducts = products;
  const filteredProducts = products.filter(
    (product) => product.productName !== null
  );
  console.log('products_____', filteredProducts);
  if (filteredProducts.length === 0) {
    return null;
  }
  console.log('brandSupportLinks', brandSupportLinks);
  return (
    <SectionProducts className="sectionProducts">
      <ProductBlockNavigation className="productBlockNavigation">
        <HeadingElement headingLevel={headingLevel}>
          {blockTitle}
        </HeadingElement>
        {brandSupportLinks && (
          <ProductBlockNavLinks>
            {brandSupportLinks.map((link) => {
              const {
                id,
                externalLink,
                linkURL,
                textLink,
                queryString,
                ariaLabel,
              } = link;
              return (
                <Link
                  key={id}
                  children={textLink}
                  to={linkURL || externalLink}
                  external={
                    typeof externalLink === 'string' && externalLink !== ''
                  }
                  ariaLabel={ariaLabel}
                  className="productNavLink"
                />
              );
            })}
          </ProductBlockNavLinks>
        )}
      </ProductBlockNavigation>
      <ProductListWrapper className="productListWrapper">
        {filteredProducts.map((product) => {
          const productImageSharp = product.salsifyImages[0]?.fileNode.gatsbyImage;
          const productImageSrc = product.salsifyImages[0]?.fileNode.publicURL;

          console.log('Feb 15', product.salesMaterialSpl)
          return (
            <ProductItem
              key={`product_${
                product.id || Math.round(Math.random() * 1000000)
              }`}
              className="productItem"
            >
              <Link
                className="productItemLink"
                to={`/${locale.code}/brands/${brandSlug}/${product.slug}`}
                ariaLabel={`Visit ${product.productName}`}
              >
                {productImageSrc && (
                  <ProductThumbnail
                    src={productImageSrc}
                    imageSharp={productImageSharp}
                  />
                )}
                <ProductItemMetaWrapper>
                  <div
                    className="inline-rating-summary-wrapper"
                    data-bv-show="inline_rating"
                    data-bv-product-id={product.salesMaterialSpl}
                  ></div>
                  <HeadingElement headingLevel={headingLevel + 1}>
                    {product.productName}
                  </HeadingElement>
                  <div>
                    <ExternalStyledLink
                      className="externalStyledLink solid-on-dark-bg hvr-bounce-to-right"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <LinkWrapper>{detailsTranslation}</LinkWrapper>
                    </ExternalStyledLink>
                  </div>
                </ProductItemMetaWrapper>
              </Link>
            </ProductItem>
          );
        })}
      </ProductListWrapper>
    </SectionProducts>
  );
};

ProductsBlock.propTypes = {};

ProductsBlock.defaultProps = {};

export default ProductsBlock;

