import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';

import RichText from '../../components/core/RichText';
import Layout from '../../components/layout';
// import SEO from '../../components/seo';
import SEO from '../../components/core/SEO';
import theme, { GlobalStyles } from '../../themes';
import BrandHeaderBar from '../../components/Brand/BrandHeaderBar';
import BrandBanner from '../../components/Brand/BrandBanner';
import ProductsBlock from '../../components/ProductsBlock';
import { LocaleProvider } from '../../components/core/Context/LocaleProvider';
import { SitemapProvider } from '../../components/core/Context/SitemapProvider';
import { sortByOrder } from '../../utils/helpers';

import { SectionDescription, DescriptionWrapper, CoreBanner } from './styles';

const DefaultTemplate = ({ pageContext, data }) => {
  const { sitemap} = pageContext;
  const {
    slug,
    brandSupportLinks,
    brandReference,
    brandPromoBanners,
    node_locale,
    pageName,
    metaTitle,
  } = data.contentfulPageBrand;
  const { locales } = data;
  const siteConfiguration = data.contentfulDevGlobalSiteConfiguration;
  const themeColors = [
    'spinSecondaryTwo',
    'spinSecondaryThree',
    'spinSecondaryOne',
    'spinAccentOne',
    'spinAccentTwo',
  ];
  const headingLevel = 1;

  return (
    <LocaleProvider.Provider value={{ code: node_locale }}>
      <SitemapProvider.Provider value={sitemap}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Layout
            footer={siteConfiguration.globalFooter}
            siteConfiguration={siteConfiguration}
            locales={locales}
            locale={node_locale}
          >
            <SEO
              locales={locales}
              title={metaTitle}
              // description={metaDescription}
              // meta={metaKeywords}
            />
            <BrandBanner brand={data.contentfulPageBrand} />
            <BrandHeaderBar
              pageName={pageName}
              brandReference={brandReference[0]}
              siteConfiguration={siteConfiguration}
              headingLevel={headingLevel}
            />
            <SectionDescription id="sectionDescription">
              <DescriptionWrapper>
                <RichText
                  richTextContent={JSON.parse(
                    brandReference[0].description.raw
                  )}
                />
              </DescriptionWrapper>
            </SectionDescription>
            {brandPromoBanners &&
              brandPromoBanners.map((item, index) => {
                const {
                  id,
                  description,
                  image,
                  title,
                  videoCodeUrl,
                  ctaReference,
                  mediaType,
                } = item;
                // const ind = (startIndex + index) % themeColors.length;
                const mappedVideo = {
                  file: {
                    contentType: 'video/youtube',
                    urlSharp: { publicURL: videoCodeUrl },
                  },
                };
                return (
                  <CoreBanner
                    backgroundColour={themeColors[index % themeColors.length]}
                    id={`brandPromoBanner_${id}`}
                    heading={title}
                    richTextEditor={description}
                    bkgImage={
                      mediaType === 'Video' && mappedVideo
                        ? mappedVideo
                        : image || null
                    }
                    bkgImageMobile={image}
                    bannerCtaReference={ctaReference || null}
                    contentPosition={index % 2 === 0 ? 'Left' : 'Right'}
                    controls
                    playing={false}
                    className="coreBanner scalingHeight "
                    headingLevel={headingLevel + 1}
                  />
                );
              })}

            <ProductsBlock
              blockTitle="Products"
              brandSlug={slug}
              products={brandReference[0].salsifyProducts}
              brandSupportLinks={brandSupportLinks}
              headingLevel={headingLevel + 1}
              translations={siteConfiguration.translations}
            />
          </Layout>
        </ThemeProvider>
      </SitemapProvider.Provider>
    </LocaleProvider.Provider>
  );
};

DefaultTemplate.propTypes = {
  pageContext: PropTypes.shape({
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.oneOfType([PropTypes.object]),
    metaKeywords: PropTypes.string,
    locale: PropTypes.oneOfType([PropTypes.object]),
    locales: PropTypes.oneOfType([PropTypes.object]),
    sitemap: PropTypes.oneOfType([PropTypes.object]),
    siteConfiguration: PropTypes.oneOfType([PropTypes.object]),
  }).isRequired,
};

export default DefaultTemplate;

export const query = graphql`
  query BrandTemplate($id: String!, $node_locale: String!) {
    contentfulDevGlobalSiteConfiguration(node_locale: { eq: $node_locale }) {
      ...SiteConfiguration
    }
    locales: allContentfulDevGlobalSiteConfiguration {
      nodes {
        node_locale
        languageSwitcherText
      }
    }
    contentfulPageBrand(id: { eq: $id }) {
      slug
      node_locale
      pageName
      metaTitle
      metaDescription {
        metaDescription
      }
      brandReference {
        ...BrandReference
      }
      brandPromoBanners {
        ...BrandPromoBanners
      }
      brandSupportLinks {
        ...BrandSupportLinks
      }
    }
  }
`;
